import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Spionerar Apple på sina användare genom sitt nya operativsystem Big Sur? Högst kontroversiellt. Vi tar även en titt på månadens nyheter från Pine64 och slår rekord i antalet Kort & Gott-punkter med FOSS North, telemetri, youtube-dl, FSF och mycket mer.`}</p>
    <h2>{`Rättning`}</h2>
    <ul>
      <li parentName="ul">{`(Från S05E03) RaspberryPi har 4 kärnor, inte 8.`}</li>
    </ul>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Apple loggar/OCSP/VPN/Brandväggs-panik:`}<ul parentName="li">
          <li parentName="ul">{`Från: `}<a parentName="li" {...{
              "href": "https://sneak.berlin/20201112/your-computer-isnt-yours/"
            }}>{`https://sneak.berlin/20201112/your-computer-isnt-yours/`}</a></li>
          <li parentName="ul">{`Till: `}<a parentName="li" {...{
              "href": "https://blog.jacopo.io/en/post/apple-ocsp/"
            }}>{`https://blog.jacopo.io/en/post/apple-ocsp/`}</a></li>
          <li parentName="ul">{`Sedan: `}<a parentName="li" {...{
              "href": "https://thenextweb.com/plugged/2020/11/16/apple-apps-on-big-sur-bypass-firewalls-vpns-analysis-macos/"
            }}>{`https://thenextweb.com/plugged/2020/11/16/apple-apps-on-big-sur-bypass-firewalls-vpns-analysis-macos/`}</a></li>
          <li parentName="ul">{`Slutligen?: `}<a parentName="li" {...{
              "href": "https://support.apple.com/en-us/HT202491"
            }}>{`https://support.apple.com/en-us/HT202491`}</a></li>
        </ul></li>
      <li parentName="ul">{`Pine64 community update: `}<a parentName="li" {...{
          "href": "https://www.pine64.org/2020/11/15/november-update-kde-pinephone-ce-and-a-peek-into-the-future/"
        }}>{`https://www.pine64.org/2020/11/15/november-update-kde-pinephone-ce-and-a-peek-into-the-future/`}</a><ul parentName="li">
          <li parentName="ul">{`Fingeravtrycksläsare: `}<a parentName="li" {...{
              "href": "https://www.reddit.com/r/PINE64official/comments/jurehy/pinephone_fingerprint_scanner_update/"
            }}>{`https://www.reddit.com/r/PINE64official/comments/jurehy/pinephone_fingerprint_scanner_update/`}</a></li>
        </ul></li>
    </ul>
    <h3>{`Kort & Gott`}</h3>
    <ul>
      <li parentName="ul">{`youtube-dl tillbaka på GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/ytdl-org/youtube-dl"
        }}>{`https://github.com/ytdl-org/youtube-dl`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.blog/2020-11-16-standing-up-for-developers-youtube-dl-is-back/"
        }}>{`https://github.blog/2020-11-16-standing-up-for-developers-youtube-dl-is-back/`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/github/dmca/blob/e00bfb544e93bfd3066fe1699171964dd2dc29e0/2020/11/2020-11-16-RIAA-reversal-effletter.pdf"
        }}>{`https://github.com/github/dmca/blob/e00bfb544e93bfd3066fe1699171964dd2dc29e0/2020/11/2020-11-16-RIAA-reversal-effletter.pdf`}</a></li>
      <li parentName="ul">{`System76 rea: `}<a parentName="li" {...{
          "href": "https://system76.com/specials"
        }}>{`https://system76.com/specials`}</a></li>
      <li parentName="ul">{`FOSS North 2020 (del 2): `}<a parentName="li" {...{
          "href": "https://foss-north.se/2020ii"
        }}>{`https://foss-north.se/2020ii`}</a></li>
      <li parentName="ul">{`Dell kill switches: `}<a parentName="li" {...{
          "href": "https://www.phoronix.com/scan.php?page=news_item&px=Dell-Hardware-Privacy-Linux"
        }}>{`https://www.phoronix.com/scan.php?page=news_item&px=Dell-Hardware-Privacy-Linux`}</a></li>
      <li parentName="ul">{`Guido van Rossum anställd av Microsoft: `}<a parentName="li" {...{
          "href": "https://twitter.com/gvanrossum/status/1326932991566700549"
        }}>{`https://twitter.com/gvanrossum/status/1326932991566700549`}</a>{` `}</li>
      <li parentName="ul">{`Dolphin får telemetri: `}<a parentName="li" {...{
          "href": "https://eang.it/user-feedback-dolphin-announce/"
        }}>{`https://eang.it/user-feedback-dolphin-announce/`}</a></li>
      <li parentName="ul">{`FSF uppdaterar Listan: `}<a parentName="li" {...{
          "href": "https://www.phoronix.com/scan.php?page=news_item&px=FSF-High-Priority-Projects-2021"
        }}>{`https://www.phoronix.com/scan.php?page=news_item&px=FSF-High-Priority-Projects-2021`}</a><ul parentName="li">
          <li parentName="ul">{`Listan: `}<a parentName="li" {...{
              "href": "https://www.fsf.org/campaigns/priority-projects/"
            }}>{`https://www.fsf.org/campaigns/priority-projects/`}</a></li>
        </ul></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`ioping: `}<a parentName="li" {...{
          "href": "https://github.com/koct9i/ioping"
        }}>{`https://github.com/koct9i/ioping`}</a></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Mastodon ropar efter alternativ till Telegram-chatt!`}</li>
      <li parentName="ul">{`Donationer, 2 Trevliga anonyma supporters`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Alex: `}<a parentName="li" {...{
          "href": "https://mastodon.online/@_alexander_"
        }}>{`@`}<em parentName="a">{`alexander`}</em></a></li>
      <li parentName="ul">{`Seb: `}<a parentName="li" {...{
          "href": "https://social.gelotte.dev/@seb"
        }}>{`@seb`}</a></li>
      <li parentName="ul">{`Seb på twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/Kikkusrikkus"
        }}>{`@Kikkusrikkus`}</a></li>
      <li parentName="ul">{`Donationer: `}<a parentName="li" {...{
          "href": "https://liberapay.com/TrevligMjukvara/donate"
        }}>{`https://liberapay.com/TrevligMjukvara/donate`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Dreamy Flashback" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Backbay Lounge" by Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0: `}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a></li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      